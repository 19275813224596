.outer-emailtemplates {}

.inner-emailtemplates {
    width: 80%;
    margin: 20px auto 0;

}

.newtemplate {
    width: 300px;
    margin: auto;
}

a {
    text-decoration: none;
    color: white;
    background-color: rgb(36, 36, 172);
    font-size: 20px;
    padding: 15px;
}

.search {
    justify-content: center;
    display: flex;
    flex-direction: row;
}

input {
    width: 400px;
}

button {
    width: 200px;
}

.or {
    margin: 20px;
    text-align: center;
}

.items {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.edit {
    width: 100px;
    background-color: greenyellow;
}

.delete {
    width: 100px;
    background-color: red;
}

.form {
    display: flex;
    flex-direction: column;
}