* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.outer-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inner-container {
    display: flex;
    width: 350px;
    flex-direction: column;
    padding: 20px;
    font-size: 20px;
}

.title {
    text-align: center;
    margin: 25px 0;
}

input {
    width: 100%;
    margin: 10px;
    padding: 10px;
    outline: none;
}

button {
    width: 100%;
    margin: 10px;
    padding: 10px;
    outline: none;
    cursor: pointer;
    border: none;
    background-color: aquamarine;
}
.dashboard {
    width: 100vw;
}

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: 2px 20px;
    padding: 20px;
    background-color: black;
    color: white;
    border-radius: 5px;
}

.title {
    font-size: 28px;
    font-weight: 500;
}

.items {
    display: flex;
    flex-direction: row;
    grid-gap: 30px;
    gap: 30px;
}

.inner_items a {
    text-decoration: none;
    color: white;
    font-size: 22px;
}
.outer-emailtemplates {}

.inner-emailtemplates {
    width: 80%;
    margin: 20px auto 0;

}

.newtemplate {
    width: 300px;
    margin: auto;
}

a {
    text-decoration: none;
    color: white;
    background-color: rgb(36, 36, 172);
    font-size: 20px;
    padding: 15px;
}

.search {
    justify-content: center;
    display: flex;
    flex-direction: row;
}

input {
    width: 400px;
}

button {
    width: 200px;
}

.or {
    margin: 20px;
    text-align: center;
}

.items {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.edit {
    width: 100px;
    background-color: greenyellow;
}

.delete {
    width: 100px;
    background-color: red;
}

.form {
    display: flex;
    flex-direction: column;
}
body {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.container {
    width: 60%;
    height: 90%;
    margin: auto;
}

.header {
    background-color: black;
    color: white;
    text-align: center;
    display: flex;
    padding: 20px;
        justify-content: center;
    align-items: center;
}

h2 {
    color: white;
    font-size: 24px;
}

.body {
    color: gray;
    height: 60%;
    min-height: 400px;
    font-size: 20px;
}
.copyright {
    height: 10%;
    background-color: red;
    color: white;
    text-align: center;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
    
                                                                                                                                
