body {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.container {
    width: 60%;
    height: 90%;
    margin: auto;
}

.header {
    background-color: black;
    color: white;
    text-align: center;
    display: flex;
    padding: 20px;
        justify-content: center;
    align-items: center;
}

h2 {
    color: white;
    font-size: 24px;
}

.body {
    color: gray;
    height: 60%;
    min-height: 400px;
    font-size: 20px;
}
.copyright {
    height: 10%;
    background-color: red;
    color: white;
    text-align: center;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
    
                                                                                                                                