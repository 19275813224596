.outer-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inner-container {
    display: flex;
    width: 350px;
    flex-direction: column;
    padding: 20px;
    font-size: 20px;
}

.title {
    text-align: center;
    margin: 25px 0;
}

input {
    width: 100%;
    margin: 10px;
    padding: 10px;
    outline: none;
}

button {
    width: 100%;
    margin: 10px;
    padding: 10px;
    outline: none;
    cursor: pointer;
    border: none;
    background-color: aquamarine;
}