.dashboard {
    width: 100vw;
}

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: 2px 20px;
    padding: 20px;
    background-color: black;
    color: white;
    border-radius: 5px;
}

.title {
    font-size: 28px;
    font-weight: 500;
}

.items {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.inner_items a {
    text-decoration: none;
    color: white;
    font-size: 22px;
}